<script setup lang="ts">
import { useSwiper } from 'swiper/vue';

const swiper = useSwiper();
const emit = defineEmits<(event: 'change', index: number) => void>();
const props = defineProps({
  currentIndex: {
    type: Number,
    default: 0,
  },
});

watch(
  () => props.currentIndex,
  (newIndex, oldIndex) => {
    if (newIndex === oldIndex) return;
    swiper.value?.slideTo(newIndex);
  },
);

swiper?.value?.on('slideChange', () => {
  emit('change', swiper.value?.realIndex);
});
</script>
<template>
  <div />
</template>
